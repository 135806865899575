import { useTranslation } from 'react-i18next'
import PlentyBody from './typography/plenty-body'
import { Icon } from './ui/icon'

export const WhyShopHere = () => {
	let { t } = useTranslation('footer')
	const getIconComponent = (name: any) => {
		return <Icon name={name} size="lg" className="text-white" />
	}

	const icons = [
		{
			icon: getIconComponent('cart-white'),
			heading: t('shop_online_or_offline_header'),
			text: t('shop_online_or_offline_body'),
		},
		{
			icon: getIconComponent('clock'),
			heading: t('save_time_header'),
			text: t('save_time_body'),
		},
		{
			icon: getIconComponent('pickup'),
			heading: t('fast_delivery_header'),
			text: t('fast_delivery_body'),
		},
		{
			icon: getIconComponent('return-arrow'),
			heading: t('easy_returns_header'),
			text: t('easy_returns_body'),
		},
	]

	return (
		<div className="w-full bg-black py-8 md:py-10">
			<div className="mx-auto grid grid-cols-2 justify-between gap-4 px-4 md:flex md:flex-row md:px-[var(--plenty-padding)]">
				{icons.map((item, index) => (
					<div key={index} className="flex flex-col items-center">
						{item.icon}
						<PlentyBody size={'lg'} className="text-white">
							{item.heading}
						</PlentyBody>
						<PlentyBody size="sm" className="text-white">
							{item.text}
						</PlentyBody>
					</div>
				))}
			</div>
		</div>
	)
}
